import React from 'react';
import Lottie from 'lottie-react';
import './LandingPage.css';
import backgroundImage from './images/backgroundImage.png'; // Import your own abstract, futuristic image
import logo from './images/logo.png'; // Import your own logo image
import animationData from './animations/blockchain-crypto.json'; // Import the downloaded Lottie animation JSON file

const LandingPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9)), url(${backgroundImage})`}}>
      <div className="absolute top-0 left-0 w-full p-3 gradient-bar flex items-center" style={{backgroundColor: 'rgba(0, 0, 255, 0.5)', height: '50px'}}>
        <img src={logo} alt="moonrubble Logo" className="h-10 w-auto ml-4 opacity-100" />
      </div>
      <Lottie animationData={animationData} loop autoPlay style={{ width: 300, height: 300 }} />
      <p className="text-lg mb-1">innovative ai/blockchain solutions</p>
      {/* <p className="text-lg mb-1">and</p>
      <p className="text-lg mb-1">innovative ai.</p> */}
      <p style={{ color: 'yellow' }} className="text-sm italic">coming soon</p>
      <div className="absolute left-1/2 top-1/4 transform -translate-x-1/2 -translate-y-1/2 text-center text-opacity-10">
        <p style={{ color: 'white' }} className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl">Building</p>
        <p style={{ color: 'white'}} className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl text-left">the</p>
        <p style={{ color: 'white'}} className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl text-left">Future</p>
      </div>
      <div className="absolute bottom-0 w-full p-3 text-center gradient-bar" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)', height: '60px'}}>
        <p style={{ color: 'white' }}>moonrubble Ltd. © 2023</p>
        <p><a href="mailto:your-email@example.com" className="underline text-yellow-500">contact us</a></p>
      </div>
    </div>
  );
};

export default LandingPage;
