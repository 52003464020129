import React from 'react';
import LandingPage from './LandingPage';


const App = () => (
  <div>
    <LandingPage />
  </div>
);

export default App;
